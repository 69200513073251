export const ALLOW_LIST = new Set<string>([
    '100640251', //jalanh
    '104862219', //punsai
    '101954021', //gakansha
    '111989027', //murpriti
    '113096103', //surapars
    '113203110', //ritikdx
    '494059', //shrvikas
    '112662699', //abhvarsh
    '106051432', //pratgp
    '112756720', //sathvikt
    '101892733', //sadiqmk
    '100524208' //shugopal
]);